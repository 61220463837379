import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../layouts/layout";
import { RichText } from "prismic-reactjs";
import SEO from "../components/Seo";
import SliceZone from "../components/SliceZone";
import Testimonials from "../components/Testimonials";
import PricingSection from "../components/slices/PricingSection";
import CtaBlock from "../components/CtaBlock";

const CityPage = ({ data }) => {
  const page = get(data, "prismic.allCitys.edges[0].node");
  if (!page) return null;

  return (
    <Layout>
      <SEO title={RichText.asText(page.page_title)} />
      <SliceZone sliceZone={page.body} trackPage={page.page_title} />
      <Testimonials />
      <PricingSection />
      <CtaBlock cta="Contact Us" ctalink="mailto:support@jeevz.com">
        Questions? Talk to our concierge team today.
      </CtaBlock>
    </Layout>
  );
};

export default CityPage;

export const query = graphql`
  query CityPageQuery($uid: String!) {
    prismic {
      allCitys(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            live
            page_title
            body {
              ... on PRISMIC_CityBodyHeader_block {
                type
                label
                primary {
                  header_background
                  intro__copy
                  page_header
                }
              }
              ... on PRISMIC_CityBodyFeature_grid {
                type
                label
                fields {
                  feature_description
                  feature_icon
                  feature_title
                }
                primary {
                  headline
                }
              }
              ... on PRISMIC_CityBodyLifestyle_image {
                type
                label
                primary {
                  headline
                  jumbo_image
                }
              }
              ... on PRISMIC_CityBodyFeature_picker__grid {
                type
                label
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
                primary {
                  headline
                }
              }
              ... on PRISMIC_CityBodyFeature_picker__strip {
                type
                label
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
              }
              ... on PRISMIC_CityBodyCovid {
                type
                label
                primary {
                  show_this_block
                }
              }
              ... on PRISMIC_CityBodySplit_block {
                type
                label
                fields {
                  header
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 960, quality: 85) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  text
                }
              }
              ... on PRISMIC_CityBodyUse_case_carousel {
                type
                label
                fields {
                  image
                  use_case_text
                  use_case_title
                }
              }
              ... on PRISMIC_CityBodyFeature_strip {
                type
                label
                fields {
                  feature_description
                  feature_icon
                  feature_title
                }
              }
            }
            appsflyer_link {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;
